
import Vue from "vue";

import * as luxon from "luxon";
import axios from "axios";
import apiUrl from "@/utils/apiUrl";

// VueFolder | FileInfo
type VueDirentBase = {
  id: string;
  name: string;
};

type VueFile = FileInfo & { children: never; type: "file" } & VueDirentBase;

type VueFolder = {
  children: VueDirent[];
  parent: string;
  type: "folder";
} & VueDirentBase;

type VueDirent = VueFolder | VueFile;

export default Vue.extend({
  name: "files",
  data: () => ({
    maxTreeHeight: 0,
    bcItems: [],
    bcItem: null as any,
    directory: [] as VueFolder[],
    active: [],
    open: [],
    cdnLink: "",
    linkedItems: [] as { no: string }[],
  }),
  computed: {
    items(): VueFolder[] {
      return this.directory;
    },
    selected(): VueDirent | undefined {
      if (!this.active.length) return undefined;
      const id = this.active[0];
      const flatDir = this.getFlaltDirectory();
      return flatDir.find((x) => x.id == id);
    },
  },
  watch: {
    async selected() {
      if (this.selected && !this.selected.children) {
        this.$vuetify.goTo(0);
        const blobLinkRes = await axios.get(
          `${apiUrl}/api/blobLink/${this.selected.id}`
        );
        this.cdnLink = `https://${
          this.$store.state.mode == "PROD"
            ? "assets-geovent"
            : "assets-geovent-staging"
        }.bipharus.com/${blobLinkRes.data.blobName}`;
        this.getLinkedItems();
      }
    },
  },
  async mounted() {
    const itemReq = await axios.get(`${apiUrl}/api/item/all`);
    this.bcItems = itemReq.data.items;

    this.$nextTick(() => {
      window.addEventListener("resize", this.updateMaxTreeHeight);
      this.updateMaxTreeHeight();
    });

    const rootsRes = await axios.get(`${apiUrl}/api/sync/roots`);

    const roots: VueFolder[] = rootsRes.data.map((x: any) => ({
      id: x._id,
      name: x.name,
      type: "folder",
      children: [],
    }));

    this.directory =
      roots.length > 0
        ? roots
        : [
            {
              id: "",
              name: "Failed to load folders",
              parent: "",
              type: "folder",
              children: [],
            },
          ];
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateMaxTreeHeight);
  },
  methods: {
    async getLinkedItems() {
      const linkedItemsRes = await axios.get(
        `${apiUrl}/api/itemDirentMap/file/${this.selected?.id}`
      );
      const linkedItems: { no: string }[] = [];
      const fetches = linkedItemsRes.data.map(async (x: any) => {
        const itemRes = await axios.get(`${apiUrl}/api/item/${x.itemId}`);
        linkedItems.push({ ...itemRes.data, mapId: x._id });
      });
      await Promise.all(fetches);
      linkedItems.sort((a, b) => a.no.localeCompare(b.no));
      this.linkedItems = linkedItems;
    },
    async linkItem() {
      if (this.selected)
        await axios.post(
          `${apiUrl}/api/link?item=${this.bcItem}&dirent=${this.selected.id}`
        );
      this.getLinkedItems();
    },
    async removeLink(id: string) {
      await axios.delete(`${apiUrl}/api/link/${id}`);
      this.getLinkedItems();
    },
    encodeUtfToBase64(val: string): string {
      const codeUnits = new Uint16Array(val.length);
      for (let i = 0; i < codeUnits.length; i++) {
        codeUnits[i] = val.charCodeAt(i);
      }
      return btoa(String.fromCharCode(...new Uint8Array(codeUnits.buffer)));
    },
    async fetchChildren(item: VueDirent) {
      if (item.type == "file") return;

      const parentPathBase64 = this.encodeUtfToBase64(
        `${item.parent ? item.parent + "/" : ""}${item.name}`
      );

      const childrenRes = await axios.get(
        `${apiUrl}/api/syncDirent/${parentPathBase64}/children`
      );

      const children: VueDirent[] = childrenRes.data.map((x: SyncDirent) => {
        if (x.type == "folder")
          return {
            id: x._id,
            name: x.name,
            type: "folder",
            parent: x.parent,
            children: [],
          } as VueFolder;
        else
          return {
            id: x._id,
            name: x.name,
            type: "file",
            lastRead: x.lastRead,
            contentChange: x.contentChange,
            anyChange: x.anyChange,
            birth: x.birth,
          } as VueFile;
      });

      item.children.push(...children);
    },
    getFlaltDirectory(): VueDirent[] {
      const run = (dirents: VueDirent[], result: VueDirent[]): VueDirent[] => {
        if (dirents.length == 0) return result;
        const children = dirents[0].children ? dirents[0].children : [];
        return run([...dirents.slice(1), ...children], [dirents[0], ...result]);
      };

      return run(this.directory, []);
    },
    updateMaxTreeHeight() {
      const element = document.getElementById("treecol");
      const bounds = element?.getBoundingClientRect();

      this.maxTreeHeight =
        element && bounds
          ? window.innerHeight - bounds.y - 40
          : window.innerHeight;
    },
    unixToHuman(unix: number) {
      return luxon.DateTime.fromMillis(unix).toFormat("yyyy-LL/dd HH:mm:ss");
    },
  },
});
